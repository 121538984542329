.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5rem 9% 2rem;
  background-color: #171717;
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
}

.project-header {
  font-size: 3rem;
  text-align: center;
  color: #ffffff;
}

.project-header span {
  background: linear-gradient(30deg, #f06, #3cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4rem;
}

.header-card, .sub-card {
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  padding: 1rem;
  width: 30%;
  min-width: 200px;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  
}
.header-card:hover, .sub-card:hover {
  transform: translateY(-10px);
}

.header-card {
  width: 80%;
  margin-bottom: 1rem;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  max-height: 25rem;
  object-fit: cover;
  display: block;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  border-radius: 1rem;
  
}

.image-container-sub img {
  width: 100%;
  max-height: 12rem;
  object-fit: cover;
  display: block;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  border-radius: 1rem;
  background: linear-gradient(30deg, #f06, #3cf);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 1rem;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.card-text-main {
  font-size: 2rem;
  margin-top: 1rem;
  z-index: 2;
}


.card-text {
  font-size: 1.3rem;
  margin-top: 1rem;
  z-index: 2;
}

.project-link a {
  display: inline-flex;
  align-items: center;
  transition: transform 0.3s ease, color 0.3s ease;
  color: var(--color-main);
  z-index: 2;
}

.project-link a:hover {
  color: rgb(165, 0, 66);
  transform: translateX(5px);
}

.arrow-icon {
  margin-left: 5px;
  font-size: 1rem;
  vertical-align: middle;
  transition: transform 0.3s ease;
}

.project-link:hover .arrow-icon {
  transform: translateX(5px);
}

.other-projects {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  border-top: 2px solid var(--color-main);
  padding-top: 1rem;
}

.school-projects {
  font-weight: 400;
}

@media only screen and (max-width: 1024px) {
  .projects {
    padding: 0;
  }
  .card-container {
    width: 130%;
  }
}