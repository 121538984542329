.contact-container {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #171717;
  overflow: hidden;
}

.contact-content {
  max-width: 80%;
  width: 100%;
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.contact-header {
  margin-bottom: 1rem; 
  font-size: 1.5rem; 
  font-size: 1.5rem;
}

.contact-header span {
  background: linear-gradient(30deg, #f06, #3cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-me {
  font-size: 3rem; 
  margin-bottom: 2rem; 
}

.contact-me span {
  background: linear-gradient(30deg, #f06, #3cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.short-info {
  margin: 0 auto;
  max-width: 50rem;
}

.contact form {
  max-width: 60%;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 2rem;
}

.contact form .input-box {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 6rem;
}

.contact form .input-box2 {
  display: flex;
  justify-content: center;
  height: 6rem;
}

.contact form .input-box input,
.contact form textarea, .input-box2 input {
  width: 100%; 
  max-width: 100%;
  padding: 1rem; 
  font-size: 1.1rem; 
  margin: 0.5rem 0.5rem;
  color: var(--color-white);
  background-color: #2b2b2b;
  border-radius: 0.8rem;
  margin: 0.7rem 0.7rem;
}

.contact form textarea {
  resize: none;
}

.btn-contactform {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  border-radius: 40px;
  font-size: 19px;
  color: var(--color-white);
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 1;
  cursor: pointer;
  margin-top: 2rem;
}

.btn-contactform::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
  background: linear-gradient(45deg, #f06, #3cf, #f06);
  z-index: -1;
  border-radius: 40px;
  background-size: 200%;
  filter: blur(5px);
  transition: .5s ease;
}

.btn-contactform:hover::before {
  background-position: 100% 0;
}

.btn-contactform::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
  background: linear-gradient(45deg, #f06, #3cf, #f06);
  background-position: 0 0;
  z-index: -1;
  border-radius: 40px;
  background-size: 200%;
  transition: .5s ease;
}

.btn-contactform:hover::after {
  background-position: 100% 0 ;
}

.buttonsubmit {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .contact form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .contact-container {
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .contact-me {
    font-size: 2.2rem;
  }
}