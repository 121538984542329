.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 9%;
  background: #0F0F0F;
  
}

.footer-text p {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.footer-text {
  max-height: 5rem;
}

.footer-iconTop a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background: linear-gradient(30deg, #f06, #3cf);
  border-radius: 0.8rem;
  transition: 0.5s ease;
}

.footer-iconTop a:hover {
  box-shadow: 0 0 1rem #f06;
}

.arrow-up {
  font-size: 1.4rem;
  color: var(--color-white);
}

@media only screen and (max-width: 420px) {
  .footer {
      flex-direction: column-reverse;
  }
  footer p {
    text-align: center;
    margin-top: 2rem;
  }
}