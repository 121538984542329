@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/* GENERAL STYLES */

* {
    text-decoration: none;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
}


html {
    width: 100%;
}

:root {
    --color-bg-main: #000;
    --color-bg-intro: linear-gradient(180deg, #0D0D0D 0%, #28262F 100%);
    --color-bg-variant: #171717;
    --color-bg-education: linear-gradient(220deg, #28262F 0%, #151515 100%);
    --color-bg-skills: linear-gradient(60deg, #161616 10.94%, #28262F 54.69%, #191919 100%);

    --color-white: #fff;
    --color-main: #F06;
    --color-gradient: linear-gradient(229.52deg, #FF2770 -13.63%, #45F3FF 111.16%);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

/* GLOBAL MEDIA QUERIES */

html {
    scroll-behavior: smooth;
}

@media (max-width: 1200px) {
    html {
        font-size: 80%;
    }
}

@media (max-width: 991px) {
    .header {
        padding: 2rem 3%;
    }

    .footer {
        padding: 2rem 3%;
    }
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg-main);
    color: var(--color-white);
    line-height: 1.7;
}

/* KEYFRAMES ANIMATIONS */

@keyframes slideTop {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideBottom {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes floatImage {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes circleRotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes slideRightVariant {
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
