.about {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 5rem 9% 2rem;
    background-color: #171717;
}

.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 80%;
}

.about-content {
    margin: 2rem 5rem 0;
}

.about-content h3 {
    font-size: 1.6rem;
}

.about-content p {
    margin: 2rem 0 2rem;
}

.about-img img {
    width: 10vw;
}

.heading {
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
}

.heading span {
    background: linear-gradient(30deg, #f06, #3cf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-content h2 {
    text-align: left;
    line-height: 1.2;
}

.introimg {
    width: 410px;
    height: 410px;
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.introimg .intro-glowing-circle::after {
    content: '';
    position: absolute;
    width: 380px;
    height: 380px;
    background: var(--color-gradient);
    border-radius: 50%;
}

.introimg .intro-glowing-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-container .btn-cta {
    opacity: 1;
    animation: none;
}

.about-content ul {
    padding-left: 1.5rem;
    margin: 1rem 0;
  }
  
  .about-content li {
    margin-left: 10px;
    list-style-type: disc;
    padding: 0.4rem 0;
  }

p span {
    color: var(--color-main);
}

@media only screen and (max-width: 1500px) {
    .about-container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .about {
        padding: 5rem 2% 2rem;
    }
}

@media only screen and (max-width: 900px) {
    .about-container {
        flex-direction: column-reverse;
        text-align: center;
    }
    
    .introimg img {
        width: 70vw;
        margin-top: 4rem;
    }

    .heading {
        font-size: 3rem;
    }

    .about-content h2 {
        text-align: center;
        margin-bottom: 2rem;
    }
    .about-content p {
        margin-bottom: 4rem;
    }
}  

@media only screen and (max-width: 800px) {
    .about-content {
        margin: 2rem 1rem 0;
    }
}

@media only screen and (max-width: 768px) {
    .about-content .btn-cta {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 400px) {
    .introimg {
        display: none;
        position: absolute;
    }
}

@media only screen and (max-width: 365px) {
    .introimg img {
        width: 90vw;
    }
}