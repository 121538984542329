.education-container {
  max-width: 100%;
  min-height: 100vh;
  padding-top: 1.5rem;
  background: var(--color-bg-education);
  justify-content: center;
  align-items: center;
}

.education-content {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 100px;
}

.education-header {
  font-size: 3rem;
  text-align: center;
  margin-top: 5rem;
}

.education-header span {
  background: linear-gradient(30deg, #f06, #3cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.education-buttons button {
  display: block;
  color: #7E7E7E;
  background: transparent;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  min-width: 10rem;
  text-align: unset;
  height: 60px;
  overflow: hidden;
  font-weight: 600;
  margin-bottom: 1rem;
}

.education-buttons .selected {
  color: var(--color-white);
  width: 30px;
  border-left: 2px solid #f06;
  padding-left: 1rem;
  animation: slideRightVariant 1s ease forwards;
}

.education-content ul {
  max-width: 80%;
}

.education-buttons {
  margin-top: 80px;
  flex: 1;
  background: rgba(22, 22, 22, .2);
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  max-width: 13rem;
  max-height: 20rem;
  margin-right: 4rem;
  border-radius: 0.5rem;
  padding-top: 1rem;
  overflow-x: auto;
  position: relative;
}

/* Scrollbar styles */
.education-buttons::-webkit-scrollbar {
  height: 4px;
}

.education-buttons::-webkit-scrollbar-thumb {
  background-color: var(--color-main);
  border-radius: 4px;
}

.education-buttons::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 5% 0 10%;
}

.button-output-content {
  flex: 2;
  padding: 4rem 0 0 2rem;
}

.button-output-content h2 {
  font-size: 26px;
}

.button-output-content li {
  color: lightgray;
  margin-top: 22px;
  max-width: 40rem;
  font-size: 1.1rem;
}

.button-output-content h4 {
  background: linear-gradient(30deg, #f06, #3cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem
}

.chevron {
  color: var(--color-main);
}

.button-output-content h5 {
  font-size: 1rem;
}

@media only screen and (max-width: 700px) {
  .button-wrapper {
    flex-direction: column;
    overflow-x: scroll;
    margin: 0;
  }

  .education-buttons {
    max-width: unset;
    max-height: unset;
    margin-top: 2rem;
    flex-direction: unset;
    gap: 0.5rem;
    margin-right: 0;
    overflow-x: scroll;
    justify-content: flex-start;
    text-align: center;
    position: relative;
  }

  .education-buttons button {
    padding-right: 2rem;
    flex: 1 0 auto;
    max-width: unset;
    overflow-x: scroll;
    width: 30px;
  }

  .education-buttons .selected {
    border: unset;
  }

  .education-buttons .selected:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 80%;
  }

  .button-output-content {
    flex: 2;
    padding: 4rem 0 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .button-output-content li {
    font-size: 1.2rem;
  }

  .button-output-content h2 {
    font-size: 1.6rem;
  }

  .button-output-content h4 {
    font-size: 1.4rem;
  }

  .button-output-content h5 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .education-header {
    font-size: 2.2rem;
  }
}
