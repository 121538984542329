.intro-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 10rem 15% 2rem;
    background: var(--color-bg-intro);
    justify-content: center;
}

.intro-content h1 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    animation: slideRight 1s ease forwards;
    opacity: 0;
    
    animation-delay: 0.7s;
}

.intro-content h3 {
    font-size: 2.2rem;
    font-weight: 700;
    background: linear-gradient(30deg, #f06, #3cf);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: 1s;
}

.intro-content p {
    max-width: 50rem;
    font-size: 1.1rem;
    color: lightgray;
    margin: 20px 0 40px;
    animation: slideLeft 0.5s ease forwards;
    opacity: 0;
    animation-delay: 1.3s;
}

.btn-cta {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    border-radius: 40px;
    font-size: 19px;
    color: var(--color-white);
    font-weight: 600;
    letter-spacing: 1px;
    z-index: 1;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    animation-delay: 1.8s;
}

.btn-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    background: linear-gradient(45deg, #f06, #3cf, #f06);
    z-index: -1;
    border-radius: 40px;
    background-size: 200%;
    filter: blur(5px);
    transition: .5s ease;
}

.btn-cta:hover::before {
    background-position: 100% 0;
}

.btn-cta::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    background: linear-gradient(45deg, #f06, #3cf, #f06);
    background-position: 0 0;
    z-index: -1;
    border-radius: 40px;
    background-size: 200%;
    transition: .5s ease;
}

.btn-cta:hover::after {
    background-position: 100% 0 ;
}

.intro-socials a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(45deg, #f06, #3cf);
    border-radius: 50%;
    font-size: 1.5rem;
    color: var(--color-white);
    margin: 0 1.5rem 3rem 0;
    z-index: 1;
}

.intro-socials a:nth-child(1) {
    opacity: 0;
    animation: slideRight 1s ease forwards;
    animation-delay: 2.1s;
}
.intro-socials a:nth-child(2) {
    opacity: 0;
    animation: slideTop 1s ease forwards;
    animation-delay: 2.1s;
}
.intro-socials a:nth-child(3) {
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: 2.1s;
}

.intro-socials a::before {
    content: '';
    position: absolute;
    background: linear-gradient(45deg, #f06, #3cf);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;
    transition: 0.5s ease;
}

.intro-socials a:hover:before {
    filter: blur(5px);
}

.intro-socials a::after {
    content: '';
    position: absolute;
    background: #19191C;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.88);
    z-index: -1;
    transition: 0.5s ease;
}

.intro-socials a:hover:after {
    transform: scale(0);
}

.intro-img {
    width: 410px;
    height: 410px;
    opacity: 0;
    animation: zoomIn 1s ease forwards, floatImage 4s ease-in-out infinite;
    animation-delay: 2.1s, 3.1s;
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-img .intro-glowing-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-img .intro-glowing-circle::after {
    content: '';
    position: absolute;
    width: 380px;
    height: 380px;
    background: #19191C;
    border-radius: 50%;
}

.intro-glowing-circle span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#f06, #3cf);
    border-radius: 50%;
    animation: circleRotate 5s linear infinite;
}

.intro-glowing-circle span:nth-child(1) {
    filter: blur(10px);
}

.intro-glowing-circle .image {
    position: relative;
    width: 380px;
    height: 380px;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
}

.image img {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 350px;
    object-fit: cover;
}

.intro-img:hover, .intro-glowing-circle:hover, .intro-glowing-circle span:hover {
    transform: scale(1.1);
    transition: 0.3s ease;
}

@media only screen and (max-width: 1200px) {
    .intro-glowing-circle {
        transform: scale(0.9);
    }

    .intro-container {
        padding: 70px 5% 0;
    }

    .intro-content h1 {
        font-size: 4rem;
    }

    .intro-content h3 {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 1024px) {
    .intro-glowing-circle {
        transform: scale(0.9);
    }
}

@media only screen and (max-width: 900px) {
    .intro-glowing-circle {
        transform: scale(0.8);
    }

    .intro-content h1 {
        font-size: 3rem;
    }

    .intro-content h3 {
        font-size: 2rem;
    }

    .btn-cta {
        width: 12rem;
        height: 4rem;
    }

    .intro-img:hover, .intro-glowing-circle:hover, .intro-glowing-circle span:hover {
        transform: scale(0.9);
    }
}

@media only screen and (max-width: 768px) {
    .intro-container {
        padding: 70px 5% 0;
        flex-direction: column;
    }

    .intro-content {
        text-align: center;
    }

    .intro-container h3 {
        font-size: 2.2rem;
    }

    .intro-container h1 {
        font-size: 3.5rem;
        margin-top: 10rem;
    }

    .btn-cta {
        margin-bottom: 2rem;
        width: 10rem;
        height: 4rem;
        font-size: 1.2rem;
    }

    .image img {
        width: 70vw;
        margin-top: 4rem;
    }
}

@media only screen and (max-width: 480px) {
    .intro-glowing-circle {
        transform: scale(0.7);
    }
    .intro-glowing-circle span {
        height: 105%;
    }

    .intro-img:hover, .intro-glowing-circle:hover, .intro-glowing-circle span:hover {
        transform: scale(0.75);
    }
}

@media only screen and (max-width: 480px)
{
    .intro-img {
        overflow: hidden;
        height: auto;
    }
}

@media only screen and (max-width: 420px) {
    .about-container .image img {
        top: 80px;
    }

    .intro-container h1 {
        font-size: 3rem;
    }
    .intro-glowing-circle span {
        width: 108%;
    }
}

@media only screen and (max-width: 400px) {
    .intro-glowing-circle span {
        width: 115%;
        height: 105%;
    }
}

@media only screen and (max-width: 365px) {
    .image img {
        width: 90vw;
    }
    .intro-glowing-circle span {
        width: 125%;
    }
}

@media only screen and (max-width: 320px) {
    .intro-glowing-circle span {
        width: 140%;
    }
}