.admin-container {
  background: var(--color-bg-intro);
  width: 100%;
  bottom: 0px;
  position: absolute;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .content {
  position: absolute;
  inset: 4px;
  background: #222;
  padding: 50px 40px;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

h4 {
  color: greenyellow;
}

p {
  margin-top: 2rem;
  margin-bottom: 8rem;
  font-weight: 500;
}