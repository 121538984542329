.skills-container {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: var(--color-bg-skills);
  overflow: hidden;
  padding: 20px;
}

.skills-content {
  max-width: 100%;
  text-align: center;
  margin-top: 50px;
}

.skills-header {
  max-width: 100%;
  margin-bottom: 2rem;
  font-size: 3rem;
}

.skills-technologies {
  max-width: 100%;
}

.skills-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  margin-top: 4rem;
  padding: 0 20%;
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 100px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;
  margin: 0 4px;
  transition: background-color 0.5s ease;
}

.dot:last-child {
  margin-right: 0;
}

.dots-container:hover .dot{
  background-color: var(--color-white);
}

.icons-css {
  color: white;
  font-size: 4rem;
  border-radius: 10px;
  padding: 5px;
  background: linear-gradient(30deg, #f06, #3cf);
}

.skills-header span {
  background: linear-gradient(30deg, #f06, #3cf);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-box {
  border-radius: 15px;
  font-size: 15px;
  width: 95px;
  height: 95px;
  display: inline-block;
  position: relative;
  color: var(--color-white);
  transition: transform .2s;
}
.box-box:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 1200px) {
  .skills-icons {
      padding: 0 5%;
  }
}

@media only screen and (max-width: 500px) {
  .skills-header {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 320px) {
  .skills-icons {
      gap: 1rem;
  }
}

