.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  transition: background-color 0.5s;
}

.logo {
  font-size: 1.6rem;
  text-decoration: none;
  color: var(--color-white);
  font-weight: 600;
  width: 2.5rem;
  height: auto;
}

.navbar {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar a {
  font-size: 1.2rem;
  color: lightgray;
  font-weight: 700;
  margin-left: 3rem;
  padding: 0 10px;
  transition: 0.3s;
}

.navbar a:hover {
  color: var(--color-white);
}

.nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--color-white);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.cv {
  padding: 10px 30px;
  background: linear-gradient(45deg, #f06, #3cf);
  transition: 0.5s;
  position: relative;
  color: var(--color-white);
  border-radius: 0.2rem;
  margin-left: 2rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

.cv a {
  all: unset;
}

.cv:hover {
  box-shadow: 0 0 35px var(--color-main);
  transform: scale(1.02);
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  .navbar {
    margin-left: 0;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #171717;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
  }

  .responsive_nav {
    transform: none;
  }

  .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .navbar a {
    font-size: 1.5rem;
    text-align: center;
  }

  .cv {
    margin-left: 3rem;
  }

  .responsive_nav .logo-open {
    display: block;
  }
}

/* STICKY NAVBAR */
.header.sticky {
  background-color: #171717;
  border-bottom: 2px solid var(--color-main);
}

@media only screen and (max-width: 1070px) {
  .header.sticky {
    height: 6rem;
    border-bottom: 3px solid var(--color-main);
  }
}
